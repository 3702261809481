export const filterConfig = { 
    inputFields: {
        'title': 'Search by organization or contact name',
        'filterName': 'Organization/Contact name',
        'fields': [ 'requesting_org_name', 'contact_name' ]
    },
    sortFields: [ {
        'type': 'Connect',
        'fields': [ {
            'title': 'Submitted Date',
            'fieldName': 'submitdate',
            'type': 'date',
            'direction': 'desc'
        },{
            'title': 'Attendees Low-High',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'asc'
        },{
            'title': 'Attendees High-Low',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'desc'
        } ]
    },{
        'type': 'Accepted',
        'fields': [ {
            'title': 'Accepted Date ',
            'fieldName': 'accept_date',
            'type': 'date',
            'direction': 'asc'
        },{
            'title': 'Attendees Low-High',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'asc'
        },{
            'title': 'Attendees High-Low',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'desc'
        } ]
    },{
        'type': 'Scheduled',
        'fields': [ {
            'title': 'Vaccination Date',
            'fieldName': 'first_vaccine_date',
            'type': 'date',
            'direction': 'desc'
        },{
            'title': 'Attendees Low-High',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'asc'
        },{
            'title': 'Attendees High-Low',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'desc'
        } ]
    },{
        'type': 'Completed',
        'fields': [ {
            'title': 'Vaccination Date',
            'fieldName': 'first_vaccine_date',
            'type': 'date',
            'direction': 'desc'
        },{
            'title': 'Attendees Low-High',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'asc'
        },{
            'title': 'Attendees High-Low',
            'fieldName': 'vaccination_wanted_indiv_num',
            'type': 'float',
            'direction': 'desc'
        } ]
    } ],
    filterFields: [ {
        'title': 'Location',
        'fieldName': 'event_location',
        'checked': [],        
        'values': [ 'Address' , 'Provider’s Location' , 'Virtually' ,'Unsure' ],
        'exact': false
    },{
        'title': 'Event Type',
        'fieldName': 'vaccination_event_type',
        'checked': [],
        'values': [ 'Educational event', 'COVID-19 vaccination only', 'Routine childhood vaccinations only', 'COVID-19 and flu vaccinations only', 'Both COVID-19 and routine childhood vaccinations', 'COVID-19, flu, and routine childhood vaccinations' ],
        'exact': true
    } ]
};