import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const Checkboxes = ({ list, checked, handleFilters }) => {

    const [ checkedArray, setCheckedArray ] = useState(checked);

    const onChangeHandler = (item) => {
        const newCheckedArray = [ ...checkedArray ];
        if (newCheckedArray.indexOf(item) > -1) {
            newCheckedArray.splice(newCheckedArray.indexOf(item), 1);
        } else {
            newCheckedArray.push(item);
        }
        handleFilters(newCheckedArray);
        setCheckedArray(newCheckedArray);
    };

    return list.map((item, index) => {
        return (
            <div key={ item }>
                <FormControlLabel
                    control={
                        <Checkbox checked={ checkedArray.indexOf(item) !== -1 } onChange={ () => onChangeHandler(item) } name={ item } />
                    }
                    label={ item }
                />
            </div>
        );
    });
};

export default Checkboxes;