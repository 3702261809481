import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Card from './Cards/Card';
import AvailableCard from './Cards/AvailableCard';
import { Select, MenuItem, InputLabel, TextField, FormControl } from '@material-ui/core';
import { filterConfig } from '../configs/filter';
import FilterModal from './Modals/FilterModal';

class Requests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: {},
            filter: this.props.filter,
            filterObj: this.props.filterObj,
            filterApplied: false,
            data: [ ],
            sort: {}
        };
        this.input = React.createRef();
        this.sortSet = [];        
    }

    componentWillMount() {
        let filterApplied = false;
        this.state.filterObj.map(filter => {
            if (filter.checked.length > 0) {
                filterApplied = true;
            }
        })
        const sortSetArray = filterConfig.sortFields.filter(obj => {
            return (obj.type == this.props.type);
        }).map(obj => {
            return obj.fields;
        });
        this.sortSet = sortSetArray[ 0 ];
        this.setState(prevState => ({ data: this.props.requests, filterApplied: filterApplied }));
    }

    render() {
        console.log(this.state);
        const lowercasedFilter = this.state.filter.toLowerCase();

        const filteredData = this.state.data.filter(item => {
            let matchesString = true;
            let matchesFilter = true;
            // if input has been filled out, see if it matches the fields 
            if (lowercasedFilter != '') {
                matchesString = filterConfig.inputFields.fields.some(key => {
                    return item[ key ].toLowerCase().includes(lowercasedFilter);
                })
            }
            // if other filter applied, check for values
            if (this.state.filterApplied) {
                matchesFilter = false;
                matchesFilter = this.state.filterObj.some(filter => {
                    if (filter.checked.length > 0) {
                        if (filter.exact) {
                            return filter.checked.indexOf(item[ filter.fieldName ]) > -1;
                        } else {
                            return filter.checked.some(val => {
                                return item[ filter.fieldName ].toLowerCase().includes(val.toLowerCase());
                            });
                        }
                    }
                })
            }
            return matchesFilter && matchesString;
        });

        const compareBy = (key, type, dir) => {
            return function (a, b) {
                let valA = a[ key ];
                let valB = b[ key ];
                if (type == 'string') {
                    valA = a[ key ].toLowerCase();
                    valB = b[ key ].toLowerCase();
                    if (dir == 'asc'){
                        return (''+ valA > ('' + valB)) ? 1 : -1;
                    } else {
                        return (''+ valA < ('' + valB)) ? 1 : -1;
                    }
                } else {
                    if (type == 'date') {
                        valA = new Date(valA);
                        valB = new Date(valB);
                    }
                    if (dir == 'asc') {
                        return (valA - valB);
                    } else {
                        return (valB - valA);
                    }
                }
            };
        }

        const sortData = (e) => {
            const sortObj = this.sortSet[ e.target.value ];
            const keyVal = sortObj[ 'fieldName' ];
            const keyType = sortObj[ 'type' ];
            const keyDir = sortObj[ 'direction' ];
            const arrayCopy = [ ...this.state.data ];
            arrayCopy.sort(compareBy(keyVal, keyType, keyDir));
            this.setState({ data: arrayCopy, shown: {} });
        }

        const handleFilter = (filterObj) => {
            let filterArr = [];
            filterObj.map(filter => {
                filterArr = filterArr.concat(filter.checked);
            });
            const filterApplied = filterArr.length > 0;
            this.props.onFilterObjChange(filterObj);
            this.setState(prevState => ({ filterObj: filterObj, filterApplied: filterApplied }));          
        }

        const handleSubmit = (event) => {
            this.props.onFilterChange(event.target.value);
            this.setState(prevState => ({ filter: event.target.value }));
        }

        const resetFilter = () => {
            // reset gloabl filter value
            this.props.onFilterChange('');          
            const filterCopy = [ ...this.state.filterObj ];
            filterCopy.map(filter => {
                filter.checked = [];
            });
            this.props.onFilterObjChange(filterCopy);            
            this.setState(prevState => ({ filter: '', filterObj: filterCopy, filterApplied: false }));
        }

        const renderFilterLabels = () => {
            return this.state.filterObj.map(obj => {
                if (obj.checked.length > 0) {
                    return obj.checked.map(checkedItem => {
                        return (<div key={ 'filterLabel_' + checkedItem } className="rounded-lg italic bg-blue-100 m-1 p-1 px-2 text-sm">{ checkedItem }</div>);
                    });
                }
            })
        }

        const cardsToShow = filteredData
            .map((card) => {
                if (this.props.type === 'Connect') {
                    return <AvailableCard key={ card.unique_key } type={ this.props.type } request={ card }></AvailableCard>;
                } else {
                    return <Card key={ card.unique_key } type={ this.props.type } request={ card }></Card>;
                }
            });
        return (
            <div>
                <div className="p-2 md:px-4">
                    <TextField id="filterStr" label={ filterConfig.inputFields.title } value={ this.state.filter } onChange={ handleSubmit } variant="outlined"  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 leading-8 transition-colors duration-200 ease-in-out" />
                </div>
                <div className="flex flex-col md:flex-row md:justify-between md:px-2 border-b border-gray-500">
                    <div className="flex flex-col w-full md:w-auto p-2 md:p-4 md:px-2">
                        <span className="inline-block">
                            <span className="bg-green-500 w-4 h-4 mr-2 rounded-full inline-flex">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24"></svg>
                            </span>
                            Educational event
                        </span>
                        <span className="inline-block">
                            <span className="bg-red-500 w-4 h-4 mr-2 rounded-full inline-flex">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24"></svg>
                            </span>
                            COVID 19 vaccinations
                        </span>
                        <span className="inline-block">
                            <span className="bg-indigo-500 w-4 h-4 mr-2 rounded-full inline-flex">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24"></svg>
                            </span>
                            Routine childhood vaccinations
                        </span>
                        <span className="inline-block">
                            <span className="bg-yellow-500 w-4 h-4 mr-2 rounded-full inline-flex">
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24"></svg>
                            </span>
                            Flu vaccinations
                        </span>
                    </div>
                    <div className="w-full flex flex-row md:w-auto p-2 px-2 md:p-4 md:px-2 content-end">
                        <div className="w-full md:w-auto flex items-end justify-between">
                            <FormControl variant="standard" sx={ { m: 1, minWidth: 60 } }>
                                <InputLabel id="sort-order-label">Sort By</InputLabel>
                                <Select
                                    labelId="sort-order-label"
                                    id="sort-order"
                                    className="w-60"
                                    defaultValue={ 0 }
                                    onChange={ sortData }
                                    label="Sort By"
                                    >
                                    {
                                        this.sortSet.map((fieldObj, index) => {
                                            return <MenuItem key={ 'sort_' + this.props.type + '_' + fieldObj.fieldName } value={ index }>{ fieldObj.title }</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="w-auto md:pl-2 flex items-end">
                            <FilterModal
                                filters={ this.state.filterObj }
                                handleFilterSub={ (values) =>
                                    handleFilter(values)
                                }
                                onClose={ this.handleClose }
                            />
                        </div>
                    </div>                  
                </div>
                <div className="bg-gray-100">
                    <div className="flex flex-col p-2 ">
                        <div className="font-bold text-lg">Matches { cardsToShow.length }</div>
                        {
                            (this.state.filterApplied || this.state.filter != '') &&
                            <div className="flex flex-wrap items-center">
                                <div>Filtered by:</div>
                                {
                                    (this.state.filter != '') &&
                                        <div className="rounded-lg italic bg-blue-100 m-1 p-1 px-2 text-sm">{ filterConfig.inputFields.filterName + ' contains "' + this.state.filter + '"' }</div>
                                }
                                { renderFilterLabels() }
                                <div className="cursor-pointer underline text-secondary-dark hover:text-secondary px-2" onClick={ () => resetFilter() }>Reset filter</div>
                            </div>
                        }
                    </div>
                    { cardsToShow.length ? cardsToShow : <div className="p-3 text-center">No Results Match</div> }
                </div>
            </div>
        );
    }
}
/* eslint-disable camelcase */
Requests.propTypes = {
    requests: PropTypes.array,
    type: PropTypes.string,
    filter: PropTypes.string,
    filterObj: PropTypes.array,
    onFilterChange: PropTypes.func,
    onFilterObjChange: PropTypes.func
}

export default Requests;