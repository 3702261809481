import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { AppContext } from '../../context/main';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const RequestModal = (props) => {
    const [ open, setOpen ] = useState(false);
    const { emailRequest } = useContext(AppContext);

    const {
        values,
        errors,
        touched,
        isValid,
        dirty,
        getFieldProps,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange
    } = useFormik({
        initialValues: {
            'provider_key': props.providerKey,
            'requestBlob': ''
        },
        /* eslint-disable camelcase */
        validationSchema: Yup.object().shape({
            requestBlob: Yup.string()
                .required('Required')
                .min(10, 'Must be more than 10 characters for request!')
                .max(255, 'Please limit initial message to less than 255 characters!')
        }),
        // eslint-disable-next-line
        onSubmit(values) {
            emailRequest(values);
            setOpen(false);
        }
    });

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#c6f6d5'
            }
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <button className="bg-secondary-light text-black shadow-md w-36 py-2 rounded-lg text-center cursor-pointer mx-auto md:mx-3.5 mt-2 hover:bg-secondary focus:outline-none"
                type="button" onClick={ handleOpen }>
                Email DC Health
            </button>
            <Modal
                aria-labelledby="request-modal-title"
                aria-describedby="request-modal-description"
                className="justify-center items-center flex"
                open={ open }
                onClose={ handleClose }
                closeAfterTransition
                BackdropComponent={ Backdrop }
                BackdropProps={ {
                    timeout: 500,
                } }
            >
                <Fade in={ open }>
                    <div className="bg-white pt-2 px-4 pb-3 w-1/3">
                        <h2 id="request-modal-title">Send Correspondence to DC Health</h2>
                        <p id="request-modal-description" className="pb-1">This is a form for generic issues related to the vaccine exchange program<br/> (please include a contact email if different than the one associated with this app)</p>
                        <form onSubmit={ handleSubmit }>
                            <div className="mb-2 w-full">
                                <TextField
                                    id='requestBlob'
                                    name='requestBlob'
                                    { ...getFieldProps('requestBlob') }
                                    label='Request*'
                                    onChange={ handleChange }
                                    onBlur={ handleBlur }
                                    placeholder='Message must be between 10-255 characters'
                                    fullWidth
                                    variant='outlined'
                                    multiline
                                    rows='4'
                                />
                            </div>
                            <div className='justify-between'>
                                <ThemeProvider theme={ theme }>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='secondary'
                                        className='mx-auto'
                                        disabled={ !(isValid && dirty) }
                                    >Submit</Button>
                                    <Button
                                        className='float-right'
                                        variant='contained'
                                        color='default'
                                        onClick={ handleClose }
                                    >Cancel</Button>
                                </ThemeProvider>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

/* eslint-disable camelcase */
RequestModal.propTypes = {
    providerKey: PropTypes.string,
}

export default RequestModal;