import React from 'react';

function Footer() {
    return(
        <footer>
            <div className="lg:container mx-auto p-2 flex flex-row justify-between lg:w-1/2">
                <img className="w-32" src={ process.env.PUBLIC_URL + '/assets/mayorLogo.png' } alt='DC Mayor Logo'/>
                <img className="w-32" src={ process.env.PUBLIC_URL + '/assets/DCHealth.png' } alt='DC Health Logo'/>                
            </div>
            <div className="bg-secondary">
                <div className="lg:container mx-auto p-2">
                    <img className="w-32" src={ process.env.PUBLIC_URL + '/assets/footerLogo.png' } alt='Powered By OCTO'/>
                </div>
            </div>
        </footer>
    );  
}

export default Footer;