import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import vaccineData from '../../context/vaccines.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { AppContext } from '../../context/main';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const ScheduleModal = (props) => {
    const [ open, setOpen ] = useState(false);
    const [ vaccines ] = useState(vaccineData);
    const { scheduleRequest } = useContext(AppContext);

    const convertDateToES = (val) => {
        const date = new Date(new Date(val).toISOString().replace(/T.+/, ' 00:00:00'));
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        return year + '-' + month + '-' + dt;
    }

    const {
        values,
        errors,
        touched,
        getFieldProps,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange
    } = useFormik({
        initialValues: {
            'unique_key': props.requestID,
            'vaccine_dates_confirmed': true,
            'first_vaccine_date': '',
            'second_vaccine_date': '',
            'vaccine_used': ''
        },
        /* eslint-disable camelcase */
        validationSchema: Yup.object().shape({
            vaccine_used: Yup.string()
                .required('Required'),
            first_vaccine_date: Yup.date()
                .required('Required')
        }),
        // eslint-disable-next-line
        onSubmit(values) {
            if (values[ 'second_vaccine_date' ] != '') {
                const dayString = values[ 'second_vaccine_date' ];
                values[ 'second_vaccine_date' ] = convertDateToES(dayString);
            } else {
                values[ 'second_vaccine_date' ] = null;
            }
            scheduleRequest(values);
            setOpen(false);
        }
    });

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#c6f6d5'
            }
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        values.first_vaccine_date = '';
        values.second_vaccine_date = '';
        values.vaccine_used = '';
        setOpen(false);
    };

    const daysToAdd = (vacineType) => {
        const dayAdd = vaccines.filter(vaccine => {
            if (vaccine.name == vacineType) {
                return vaccine.days
            }
        })
        if (dayAdd.length > 0) {
            return dayAdd[ 0 ].days;
        } else {
            return 'error';
        }
    }

    return (
        <>
            <button className="bg-secondary-light text-black shadow-md w-36 py-2 rounded-lg text-center cursor-pointer mx-auto md:mx-3.5 mt-2 hover:bg-secondary focus:outline-none"
                type="button" onClick={ handleOpen }>
                Schedule
            </button>
            <Modal
                aria-labelledby="schedule-modal-title"
                aria-describedby="schedule-modal-description"
                className="justify-center items-center flex"
                open={ open }
                onClose={ handleClose }
                closeAfterTransition
                BackdropComponent={ Backdrop }
                BackdropProps={ {
                    timeout: 500,
                } }
            >
                <Fade in={ open }>
                    <div className="bg-white pt-2 px-4 pb-3 w-1/3">
                        <h2 id="schedule-modal-title">Schedule</h2>
                        <form onSubmit={ handleSubmit }>
                            {
                                // handle a non covid vaccine event
                                (props.eventType != 'Educational event' && props.eventType != 'Routine childhood vaccinations only')
                                ?
                                    <div>
                                        <p id="schedule-modal-description">First select the vaccine type, and then select first vaccine date.  The second vaccine date will populate appropriately.</p>
                                        <div className="mb-2 w-full">
                                            <Select
                                                id='vaccine_used'
                                                name='vaccine_used'
                                                { ...getFieldProps('vaccine_used') }
                                                onChange={ (e) => {
                                                    const { value } = e.target;
                                                    handleChange;
                                                    setFieldValue('vaccine_used', value);
                                                    const firstVaccine = values.first_vaccine_date;
                                                    if (firstVaccine != '') {
                                                        setFieldValue('first_vaccine_date', firstVaccine);
                                                        const daysAdd = daysToAdd(value);
                                                        if (daysAdd != 'error' && daysAdd != 0) {
                                                            const dateVal = new Date(firstVaccine).setDate(new Date(firstVaccine).getDate() + daysAdd + 1);
                                                            setFieldValue('second_vaccine_date', (new Date(dateVal)).toLocaleDateString('en-US'));
                                                        } else {
                                                            setFieldValue('second_vaccine_date', '');
                                                        }
                                                    }
                                                } }
                                                onBlur={ handleBlur }
                                                fullWidth
                                                variant='outlined'
                                                displayEmpty
                                            >
                                                <MenuItem value=''>--Select Vaccine Type--</MenuItem>
                                                {
                                                    vaccineData.map(({ name, value }) => {
                                                        return (
                                                            <MenuItem key={ name } value={ name }>{ name }</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                        <div className="mb-2 w-full">
                                            <TextField
                                                id='first_vaccine_date'
                                                name='first_vaccine_date'
                                                { ...getFieldProps('first_vaccine_date') }
                                                type='date'
                                                label='First Vaccine Date*'
                                                onChange={ (e) => {
                                                    const { value } = e.target;
                                                    handleChange;
                                                    console.log(value);
                                                    setFieldValue('first_vaccine_date', value);
                                                    if (value != '') {
                                                        const vaccineType = values.vaccine_used;
                                                        const daysAdd = daysToAdd(vaccineType);
                                                        if (daysAdd != 'error' && daysAdd != 0) {
                                                            const dateVal = new Date(value).setDate(new Date(value).getDate() + daysAdd + 1);
                                                            setFieldValue('second_vaccine_date', (new Date(dateVal)).toLocaleDateString('en-US'));
                                                        } else {
                                                            setFieldValue('second_vaccine_date', '');
                                                        }
                                                    } else {
                                                        setFieldValue('second_vaccine_date', '');
                                                    }
                                                } }

                                                onBlur={ handleBlur }
                                                disabled={ values.vaccine_used == '' }
                                                fullWidth
                                                variant='outlined'
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </div>
                                        <div className="mb-2 w-full">
                                            <TextField
                                                id='second_vaccine_date'
                                                name='second_vaccine_date'
                                                { ...getFieldProps('second_vaccine_date') }
                                                label='Second Vaccine Date*'
                                                disabled
                                                fullWidth
                                                variant='outlined'
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </div>
                                    </div>
                                :
                                    <div className="">
                                        <div className="mb-2 w-full">
                                            { props.eventType }
                                        </div>
                                        <div className="mb-2 w-full">
                                            <TextField
                                                id='first_vaccine_date'
                                                name='first_vaccine_date'
                                                { ...getFieldProps('first_vaccine_date') }
                                                type='date'
                                                label='Scheduled Date*'
                                                onChange={ (e) => {
                                                    const { value } = e.target;
                                                    handleChange;
                                                    setFieldValue('first_vaccine_date', value);
                                                    setFieldValue('vaccine_used', 'N/A');
                                                } }
                                                onBlur={ handleBlur }
                                                fullWidth
                                                variant='outlined'
                                                InputLabelProps={ { shrink: true } }
                                            />                                                                                                                          
                                        </div>
                                    </div>
                            }
                            <div className='justify-between'>
                                <ThemeProvider theme={ theme }>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='secondary'
                                        className='mx-auto'
                                    >Submit</Button>
                                    <Button
                                        className='float-right'
                                        variant='contained'
                                        color='default'
                                        onClick={ handleClose }
                                    >Cancel</Button>
                                </ThemeProvider>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

/* eslint-disable camelcase */
ScheduleModal.propTypes = {
    requestID: PropTypes.string,
    eventType: PropTypes.string
}

export default ScheduleModal;