import React, { useContext, useEffect } from 'react';
import Tabs from '../components/Tabs';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AppContext } from '../context/main';
import PropTypes from 'prop-types';

const Home = props => {

    const { state, checkAPI, toastMethod, getRequests } = useContext(AppContext);
    const { validKey, error, loading, showToast, toast } = state;

    const emailVaccine = process.env.REACT_APP_VACCINE_EMAIL;

    function handleClose() {
        toastMethod(false, null, null);
        getRequests();
    }

    useEffect(() => {
        checkAPI();
    }, []);
    if (error){
        return <div className="text-center w-full pt-5">Error: { error }</div>;
    } else if (loading && !validKey) {
        return <div className="text-center w-full pt-5">Loading page...</div>;
    } else if (validKey) {
        return <div className="lg:w-5/6 xl:w-3/4 container mx-auto p-2 text-gray-700">
            <h1 className="text-2xl text-center">Vaccine Exchange Connect</h1>
            <div className="">
                <p className="text-center">
                    Vaccine Exchange is the DC Health system for organizations to submit requests for on premises mass vaccinations by local DC Health qualified providers.
                    <br /><br />
                    For questions about the DC Vaccine Exchange program, email us at&nbsp;
                    <a className="cursor-pointer underline text-secondary-dark hover:text-secondary" href={ 'mailto:' + emailVaccine }>{ emailVaccine }</a>.
                    <br/><br/>
                    Use the tabs below to navigate between available, accepted, scheduled and completed requests.
                </p>
            </div>
            <Tabs></Tabs>
            <Snackbar open={ showToast } autoHideDuration={ 2000 } onClose={ handleClose }>
                <MuiAlert elevation={ 6 } variant='filled' onClose={ handleClose } severity={ toast.severity }>
                    { toast.message }
                </MuiAlert>
            </Snackbar>
        </div>
    } else {
        return <div className="lg:w-1/2 container mx-auto p-2">
            <h1 className="text-2xl text-center">Vaccine Exchange Connect</h1>
            <div className="w-full mt-3">
                <p className="text-center">
                    You are not a valid user.  Please check your URL or contact an administrator if you think this is in error.
                    <br/><br/>
                    For questions about the DC Vaccine Exchange program, email us at&nbsp;
                    <a className="cursor-pointer underline text-secondary-dark hover:text-secondary" href={ 'mailto:' + emailVaccine }>{ emailVaccine }</a>.
                </p>
            </div>
        </div>
    }

}

Home.propTypes = {
    location: PropTypes.shape({
        key: PropTypes.string
    })
}

export default Home;
