import React, { useContext, useState } from 'react';
import { AppContext } from '../context/main';
import PropTypes from 'prop-types';
import CompleteModal from './Modals/CompleteModal';
import ConfirmDialog from './Modals/ConfirmDialog';
import ScheduleModal from './Modals/ScheduleModal';

const ButtonComp = props => {

    const { state, acceptRequest, releaseRequest, providerComplete } = useContext(AppContext);
    const [ confirmOpen, setConfirmOpen ] = useState(false);
    const { id, type, eventType } = props;

    if (type  == 'Connect') {
        return <div>
            <div className="bg-secondary-light text-black shadow-md w-36 py-2 rounded-lg text-center cursor-pointer mx-auto md:mx-3.5 mt-2 hover:bg-secondary"
                    onClick={ () => setConfirmOpen(true) }>Accept</div>
            <ConfirmDialog
                title="Accept Request"
                open={ confirmOpen }
                setOpen={ setConfirmOpen }
                onConfirm={ () => acceptRequest(id) }
                >
                Do you want to accept this request?
            </ConfirmDialog>
        </div>;
    } else if (type == 'Accepted') {
        return <div>
            <div className="bg-secondary-light text-black shadow-md w-36 py-2 rounded-lg text-center cursor-pointer mx-auto md:mx-3.5 mt-2 hover:bg-secondary"
                    onClick={ () => setConfirmOpen(true) }>Release</div>            
            <ConfirmDialog
                title="Release Request"
                open={ confirmOpen }
                setOpen={ setConfirmOpen }
                onConfirm={ () => releaseRequest(id) }
                >
                Do you want to release this request for other providers to accept?
            </ConfirmDialog>            
            <ScheduleModal requestID={ id } eventType={ eventType }></ScheduleModal>
        </div>
    } else if (type == 'Scheduled') {
        return <div>
            <CompleteModal requestID={ id } eventType={ eventType } ></CompleteModal>
        </div>;
    } else {
        return null;
    }
}

ButtonComp.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    eventType: PropTypes.string
}

export default ButtonComp;