import React, { useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Checkboxes from './Checkboxes';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const FilterModal = (props) => {
    const [ open, setOpen ] = useState(false);
    const { onClose, handleFilterSub, filters } = props;
    const initialObj = JSON.parse(JSON.stringify(filters))
    const [ selValues, setSelValues ] = useState(initialObj);    
    const theme = createTheme({
        palette: {
            secondary: {
                main: '#c6f6d5'
            }
        }
    });

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => {
        setSelValues(filters);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        handleFilterSub(selValues);
        setOpen(false);
    };

    const handleChange = (values, key) => {
        const newFilterVals = [ ...selValues ];
        newFilterVals.map(filter => {
            if (filter.fieldName === key) {
                filter.checked = values;
            }
        })
        setSelValues(newFilterVals);
    }

    return (
        <>
            <Button variant="outlined" onClick={ handleOpen }>
                <FilterList />
            </Button>
            <Dialog
                aria-labelledby="filter-modal-title"
                aria-describedby="filter-modal-description"
                className="justify-center items-center flex"
                open={ open }
                onClose={ handleClose }
                closeAfterTransition
                fullScreen={ fullScreen }
                BackdropComponent={ Backdrop }
                BackdropProps={ {
                    timeout: 500,
                } }
            >
                <Fade in={ open }>
                    <div className="bg-white pt-2 px-4 pb-3 h-auto">
                        <div className="w-full flex justify-end cursor-pointer">
                            <div onClick={ handleClose }>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </div>
                        <h2 id="filter-modal-title">Filter by:</h2>
                        <div>
                            <div className="mb-2 w-full">
                                { (selValues.map((filter, filterIndex) => {
                                    return <div key={ 'filter_' + filter.fieldName } >
                                        <span>{ filter.title }</span>
                                        <div className="flex flex-col">
                                            <Checkboxes
                                                list={ filter.values }
                                                checked={ filter.checked }
                                                handleFilters={ (values) =>
                                                    handleChange(values, filter.fieldName)
                                                }
                                            />
                                        </div>
                                    </div>
                                })) 
                                }
                            </div>
                            <div className='justify-end my-auto flex flex-row'>
                                <ThemeProvider theme={ theme }>
                                    <Button
                                        onClick={ handleSubmit }
                                        variant='contained'
                                        color='secondary'
                                        className='normal-case'
                                    >Apply Filter</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Dialog>
        </>
    );
}

/* eslint-disable camelcase */
FilterModal.propTypes = {
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    handleFilterSub: PropTypes.func,
    filters: PropTypes.array
}

export default FilterModal;