import React from 'react';

import Home from './pages/Home';
import Confirm from './pages/Confirm';
import Header from './components/Header';
import Footer from './components/Footer';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from 'react-router-dom';

import { AppContextProvider } from './context/main';

function App() {

    return (
        <AppContextProvider>
            <div className="flex flex-col min-h-screen bg-tertiary-light">
                <Header image="logo.png" altText="DC Vaccine Exchange Connect Logo"></Header>
                <div className="flex-1 flex-grow">
                    <Router>
                        <Switch>
                            <Route exact path="/">
                                <Home />
                            </Route>
                            <Route path="/confirm">
                                <Confirm />
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </div>
        </AppContextProvider>
    );
}

export default App;
