import React, { useContext, useEffect, useState } from 'react';
import Requests from './Requests';
import { AppContext } from '../context/main';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import RequestModal from './Modals/RequestModal';
import { filterConfig } from '../configs/filter';

const theme = createTheme({
    palette: {
        primary: {
            main: '#21857C',
          }
    },
});

const Tabs = props => {
    const { state, getRequests } = useContext(AppContext);
    const { loading, error, data, loadingMessage, providerInfo } = state;
    const [ activeTab, setActiveTab ] = useState('Available');
    const [ filterString, setFilterString ] = useState('');
    const [ filterObj, setFilterObj ] = useState(filterConfig.filterFields);
    const [ autoRefresh, setAutoRefresh ] = useState(false);

    useEffect(() => {
        getRequests();
        let refreshInterval;
        if (autoRefresh) {
            refreshInterval = setInterval(() => {
                getRequests();
            }, 60000);
        } else {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        }
        return () => clearInterval(refreshInterval);
    }, [ autoRefresh ]);

    const handleSwitchChange = (event) => {
        setAutoRefresh(event.target.checked);
    };

    if (error){
        return <div className="text-center w-full pt-5">Error: { error }</div>;
    } else if (loading) {
        return <div className="text-center w-full pt-5">{ loadingMessage }</div>;
    } else {
        return (
            <div className="p-2">
                <div className="flex flex-row justify-end">
                    <RequestModal providerKey={ providerInfo.provider_key }></RequestModal>
                </div>
                <div className="flex flex-row mx-auto mt-4">
                    <div className={ 'flex-wrap cursor-pointer w-1/4 rounded-t-lg border-tertiary border text-center py-2 text-sm md:text-base flex flex-row justify-center' + (activeTab === 'Available' ? ' bg-white border-b-0 text-black' : ' bg-primary-light text-black hover:bg-primary-dark hover:text-black') } onClick={ () => setActiveTab('Available') }>
                        <div className="w-full md:w-auto">Available</div>
                        <div className={ 'rounded-3xl ml-1 md:ml-2 px-1.5 md:px-2 text-gray-700 border-gray-700 border' + (activeTab === 'Available' ? ' bg-blue-50' : ' bg-gray-50') }>{ data.available.length }</div>
                    </div>
                    <div className={ 'flex-wrap cursor-pointer w-1/4 rounded-t-lg border-tertiary border text-center py-2 text-sm md:text-base flex flex-row justify-center' + (activeTab === 'Accepted' ? ' bg-white border-b-0 text-black' : ' bg-primary-light text-black hover:bg-primary-dark hover:text-black') } onClick={ () => setActiveTab('Accepted') }>
                        <div className="w-full md:w-auto">Accepted</div>
                        <div className={ 'rounded-3xl ml-1 md:ml-2 px-1.5 md:px-2 text-gray-700 border-gray-700 border ' + (activeTab === 'Accepted' ? ' bg-blue-50' : ' bg-gray-50') }>{ data.accepted.length }</div>
                    </div>
                    <div className={ 'flex-wrap cursor-pointer w-1/4 rounded-t-lg border-tertiary border text-center py-2 text-sm md:text-base flex flex-row justify-center' + (activeTab === 'Scheduled' ? ' bg-white border-b-0 text-black' : ' bg-primary-light text-black hover:bg-primary-dark hover:text-black') } onClick={ () => setActiveTab('Scheduled') }>
                        <div className="w-full md:w-auto">Scheduled</div>
                        <div className={ 'rounded-3xl ml-1 md:ml-2 px-1.5 md:px-2 text-gray-700 border-gray-700 border text-sm md:text-base ' + (activeTab === 'Scheduled' ? ' bg-blue-50' : ' bg-gray-50') }>{ data.scheduled.length }</div>
                    </div>
                    <div className={ 'flex-wrap cursor-pointer w-1/4 rounded-t-lg border-tertiary border text-center py-2 text-sm md:text-base flex flex-row justify-center' + (activeTab === 'Completed' ? ' bg-white border-b-0 text-black' : ' bg-primary-light text-black hover:bg-primary-dark hover:text-black') } onClick={ () => setActiveTab('Completed') }>
                        <div className="w-full md:w-auto">Completed</div>
                        <div className={ 'rounded-3xl ml-1 md:ml-2 px-1.5 md:px-2 text-gray-700 border-gray-700 border text-sm md:text-base ' + (activeTab === 'Completed' ? ' bg-blue-50' : ' bg-gray-50') }>{ data.completed.length }</div>
                    </div>
                </div>
                <div className="border-tertiary border border-t-0 bg-white shadow-md">
                    {
                        (() => {
                            switch (activeTab) {
                                case 'Available': return <Requests key='available_tab' requests={ data.available } type='Connect' filter={ filterString } onFilterChange={ (str) => setFilterString(str) } filterObj={ filterObj } onFilterObjChange={ (obj) => setFilterObj(obj) }></Requests>;
                                case 'Accepted': return <Requests key='accepted_tab' requests={ data.accepted } type='Accepted' filter={ filterString } onFilterChange={ (str) => setFilterString(str) } filterObj={ filterObj } onFilterObjChange={ (obj) => setFilterObj(obj) }></Requests>;
                                case 'Scheduled': return <Requests key='scheduled_tab' requests={ data.scheduled } type='Scheduled' filter={ filterString } onFilterChange={ (str) => setFilterString(str) } filterObj={ filterObj } onFilterObjChange={ (obj) => setFilterObj(obj) }></Requests>;
                                case 'Completed': return <Requests key='completed_tab' requests={ data.completed } type='Completed' filter={ filterString } onFilterChange={ (str) => setFilterString(str) } filterObj={ filterObj } onFilterObjChange={ (obj) => setFilterObj(obj) }></Requests>;
                                default: null;
                            }
                        })()
                    }
                </div>
                <ThemeProvider theme={ theme }>
                    <FormControlLabel
                        control={ <Switch checked={ autoRefresh } onChange={ handleSwitchChange } color = "primary" /> }
                        label="Refresh data every 1 minute"
                    />
                </ThemeProvider>
                <div className="text-right">Data last updated: { data.refreshedTime }</div>
            </div>
        );
    }
}

export default Tabs;
