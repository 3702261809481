import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../context/main';

const Confirm = props => {
    const query = new URLSearchParams(useLocation().search);
    const { state, requestorComplete } = useContext(AppContext);
    const { error, loading, requestMessage } = state;

    useEffect(() => {

        requestorComplete(query.get('id'), query.get('response'));
    }, []);
    if (loading) {
        return <div className="text-center w-full pt-5">Loading page...</div>
    } if (error) {
        return <div className="text-center w-full pt-5">{ error }</div>
    } else {
        return <div className="text-center w-full pt-5">{ requestMessage }</div>
    }
}
export default Confirm;