import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const ConfirmDialog = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            open={ open }
            onClose={ () => setOpen(false) }
            aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">{ title }</DialogTitle>
            <DialogContent>{ children }</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={ () => {
                        setOpen(false);
                        onConfirm();
                    } }
                    className="bg-green-700 text-white">
                    Yes
                </Button>
                <Button
                    variant="contained"
                    onClick={ () => setOpen(false) }
                    className="text-red-800">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onConfirm: PropTypes.func
}
export default ConfirmDialog;