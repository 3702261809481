import React, { Component } from 'react';
import ButtonComp from '../ButtonComp';
import PropTypes from 'prop-types';

class Card extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        };
    }

    renderDateContent(request) {
        const confirmationValue = (val) => {
            let textStr = '';
            switch (val){
                case true:
                    textStr = 'Complete';
                    break;
                case false:
                    textStr = 'Incomplete';
                    break;
                case null:
                default:
                    textStr = 'Not yet provided';
            }
            return textStr;
        }

        const dateVals = (requestObj) => {
            if (requestObj.second_vaccine_date != null) {
                return <>
                    <div className="">First Vaccine Date: { new Date(requestObj.first_vaccine_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }</div>
                    <div className="">Second Vaccine Date: { new Date(requestObj.second_vaccine_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }</div>
                </>
            } else {
                if (requestObj.vaccination_event_type != 'Educational event') {
                    return <>
                        <div className="">Vaccine Date: { new Date(requestObj.first_vaccine_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }</div>
                    </>
                } else {
                    return <>
                        <div className="">Event Date: { new Date(requestObj.first_vaccine_date).toLocaleDateString('en-US', { timeZone: 'UTC' }) }</div>
                    </>
                }
            }
        }
        switch(this.props.type) {
            case 'Accepted':
                return <div>
                    <div className="">Accepted By: { request.provider_accepted_request }</div>
                    <div className="">Accepted: { new Date(request.accept_date).toLocaleDateString() } { new Date(request.accept_date).toLocaleTimeString() }</div>
                </div>
            case 'Scheduled':
                return <div>
                    <div className="">Vaccine Type: { request.vaccine_used }</div>
                    { dateVals(request) }
                </div>
            case 'Completed':
                return <div>
                    <div className="">Vaccine Type: { request.vaccine_used }</div>
                    { dateVals(request) }
                    <div className="">Provider Confirmed: { request.provider_confirmed ? 'Complete' : 'Incomplete' }</div>
                    {
                        (request.vaccination_event_type != 'Educational event') &&
                            <div className="">Actual Number Vaccinated: { request.actual_number_vaccinated }</div>
                    }
                    <div className="">Requester Confirmed: { confirmationValue(request.requester_confirmed) }</div>
                </div>                
            default:
                return null;
        }
    }

    cleanvalue = (val, type) => {
        if (type == 'date') {
            const dateVal = new Date(val);
            const dateString = dateVal.toLocaleDateString();
            return dateString;
        } else {
            return val;
        }
    }

    boolvalue = (val) => {
        if (val) {
            return 'Yes';
        } else {
            return 'No';
        }
    }

    render() {
        const { showMore } = this.state;        
        const { request, type } = this.props;
        let requestDate = '';

        if (request.submitdate) {
            const date = new Date(request.submitdate)
            requestDate = date.toLocaleDateString('en-US', { timeZone: 'UTC' });
        }
        return (
            <div className="w-full p-2">
                <div className="p-2 bg-white border border-gray-300 rounded shadow-md">
                    <div className="w-full flex flex-row justify-between">
                        <div className="w-full">
                            <div className="flex-grow md:pl-5">
                                <div className="flex flex-col md:flex-row justify-between">
                                    <div className="w-full md:w-1/2 p-2">
                                        <h2 className="title-font font-medium text-lg text-gray-900 underline cursor-pointer" onClick={ (e) => this.setState({ showMore: !showMore }) }>{ request.requesting_org_name }</h2>
                                        <h3 className="text-gray-500 mb-3">{ request.facility_or_business_type }</h3>
                                        <div className="text-gray-500 mb-3">
                                            <div className="flex flex-row my-auto ml-1">
                                                <span className="my-auto pr-2">
                                                    {  (request.vaccination_event_type.indexOf('Educational') >-1) && 
                                                        <span title={ request.vaccination_event_type } className="bg-green-500 rounded-full inline-flex items-center justify-center">
                                                            <svg width="12px" height="12px" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24"></svg>
                                                        </span>
                                                    }
                                                    {  (request.vaccination_event_type.indexOf('COVID') >-1) && 
                                                        <span title={ request.vaccination_event_type } className="bg-red-500 rounded-full inline-flex items-center justify-center">
                                                            <svg width="12px" height="12px" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24"></svg>
                                                        </span>
                                                    }
                                                    {  (request.vaccination_event_type.indexOf('childhood') >-1) && 
                                                        <span title={ request.vaccination_event_type } className="bg-indigo-500 rounded-full inline-flex items-center justify-center">
                                                            <svg width="12px" height="12px" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24"></svg>
                                                        </span>
                                                    }
                                                    {  (request.vaccination_event_type.indexOf('flu') >-1) && 
                                                        <span title={ request.vaccination_event_type } className="bg-yellow-500 rounded-full inline-flex items-center justify-center">
                                                            <svg width="12px" height="12px" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" viewBox="0 0 24 24"></svg>
                                                        </span>
                                                    }                                                    
                                                </span>
                                                <span>{ request.vaccination_event_type }</span>
                                            </div>                                        
                                            <div className="flex flex-row my-auto">
                                                <span className="my-auto pr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </span>
                                                <span>Request submitted: { requestDate }</span>
                                            </div>
                                            <div className="flex flex-row">
                                                <span className="my-auto pr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                                                    </svg>
                                                </span>
                                                <span>{ request.event_location }</span>
                                            </div>
                                            <div className="flex flex-row my-auto">
                                                <span className="my-auto pr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                    </svg>
                                                </span>
                                                <span>{ request.request_time_of_event }</span>
                                            </div>
                                            <div className="flex flex-row my-auto">
                                                <span className="my-auto pr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                                    </svg>
                                                </span>
                                                <span>{ request.contact_name }</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex-grow md:w-auto my-auto p-2">
                                        <span className="inline-flex">
                                            { this.renderDateContent(request) }
                                        </span>
                                    </div>                                                            
                                    <div className="w-full md:w-52 md:my-auto flex justify-center mx-auto">
                                        {  (request.second_vaccine_date != null)
                                            ? <ButtonComp id={ request.unique_key } type={ type } secondDate={ request.second_vaccine_date } eventType={ request.vaccination_event_type }></ButtonComp>
                                            : <ButtonComp id={ request.unique_key } type={ type } secondDate={ request.first_vaccine_date } eventType={ request.vaccination_event_type }></ButtonComp>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="w-full pt-2 flex flex-col text-center my-auto">
                                <div onClick={ (e) => this.setState({ showMore: !showMore }) } className="cursor-pointer text-secondary-dark hover:text-secondary text-xs">
                                    {
                                        (showMore) ?
                                            <div>
                                                <span>Hide Details</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                                                </svg>
                                            </div>
                                        : 
                                            <div>
                                                <span>Show Details</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </div>
                                    } 
                                </div>
                            </div>
                        </div>
                    </div>
                    {   showMore 
                    ? 
                        <div className="m-2 md:m-4 lg:grid lg:grid-cols-2 lg:gap-4">
                            <div className="bg-gray-100 p-2 my-2 w-full border border-gray-400">
                                <div className="underline text-lg font-semibold">Organization Information:</div>
                                <div className="fieldLabel">Name: <span className="fieldValue">{ request.requesting_org_name }</span></div>
                                <div className="fieldLabel">Type: <span className="fieldValue">{ request.facility_or_business_type }</span></div>
                                { 
                                    (request.org_address) &&
                                        <div className="fieldLabel">Address: <br/><a title='Google Directions' className="cursor-pointer underline text-secondary-dark hover:text-secondary" target="_blank" href={ 'https://maps.google.com/maps?daddr=' + request.org_address } ><span className="fieldValue">{ request.org_address }</span></a></div>
                                }
                                {
                                    (request.org_address  && request.org_address_additional ) && 
                                    <div className="fieldLabel">Suite, Unit, Floor: <span className="fieldValue">{ request.org_address_additional }</span></div>
                                }
                                {
                                    (request.org_zipcode) && <div className="fieldLabel">Zip Code: <span className="fieldValue">{ request.org_zipcode }</span></div>
                                }
                                {
                                    (request.org_description) && <div className="fieldLabel">Description: <span className="fieldValue">{ request.org_description }</span></div>
                                }
                                <div className="underline text-lg font-semibold mt-2">Contact Information</div>
                                <div className="fieldLabel">Name: <span className="fieldValue">{ request.contact_name }</span></div>
                                <div className="fieldLabel">Phone: <span className="fieldValue">{ request.contact_phone }</span></div>
                                <div className="fieldLabel">Email: <br/><a href={ 'mailto:' + request.contact_email } className="cursor-pointer underline text-secondary-dark hover:text-secondary"><span className="fieldValue">{ request.contact_email }</span></a></div>
                            </div>                    
                            <div className="bg-gray-100 p-2 my-2 w-full border border-gray-400">
                                <div className="underline text-lg font-semibold">Event Information:</div>
                                <div className="fieldLabel">Event Type: <span className="fieldValue">{ request.vaccination_event_type }</span></div>
                                <div className="fieldLabel">Part of a larger event? <span className="fieldValue">{ this.boolvalue(request.part_of_larger_event) }</span></div>
                                <div className="fieldLabel">Anticipated participants: <span className="fieldValue">{ request.vaccination_wanted_indiv_num }</span></div>
                                <div className="fieldLabel">Assessed vaccination of participants? <span className="fieldValue">{ this.boolvalue(request.vaccination_status_assessed) }</span></div>
                                {
                                    (request.vaccination_event_type == 'Educational event') ?
                                        <span>
                                            <div className="fieldLabel">Topics to cover: <span className="fieldValue">{ request.edu_event_topics_to_cover }</span></div>
                                        </span>
                                    :
                                        <span>
                                            <div className="fieldLabel">Vaccine Preference: <span className="fieldValue">{ request.covid_vaccine_preference }</span></div>
                                            <div className="fieldLabel">Target Demographics: <span className="fieldValue">{ request.target_demographics }</span></div>
                                            <div className="fieldLabel">Insurance status of participants: <span className="fieldValue">{ request.insurance_status }</span></div>                                                                                        
                                        </span>
                                }
                            </div>
                            <div className="bg-gray-100 p-2 my-2 w-full border border-gray-400">
                                <div className="underline text-lg font-semibold">Space Information:</div>
                                <div className="fieldLabel">Where like to hold event: <span className="fieldValue">{ request.event_location }</span></div>
                                {
                                    (request.event_location.indexOf('At the following') > -1 ) ?
                                        <span>
                                            <div className="fieldLabel">Host inside or outside: <span className="fieldValue">{ request.hosting_plan_indoor_outdoor }</span></div>
                                            <div className="fieldLabel">Host inside if inclement weather? <span className="fieldValue">{ this.boolvalue(request.facility_capacity_bad_weather) }</span></div>
                                            <div className="fieldLabel">On-site amenities: <span className="fieldValue">{ request.on_site_amenities }</span></div>
                                            <div className="fieldLabel">Special language or access accommodations: <span className="fieldValue">{ request.language_needs }</span></div>                                            
                                        </span>
                                    :
                                        null
                                }
                            </div>
                            <div className="bg-gray-100 p-2 my-2 w-full border border-gray-400">
                                <div className="underline text-lg font-semibold">HR &amp; Other Information</div>
                                <div className="fieldLabel">Staff volunteers available? <span className="fieldValue">{ this.boolvalue(request.staff_volunteers_avail) }</span></div>
                                <div className="fieldLabel">General Comments: <span className="fieldValue">{ request.general_comments }</span></div>
                            </div>
                        </div>
                    : null
                    }
                </div>
            </div>
        );
    }
}
/* eslint-disable camelcase */
Card.propTypes = {
    showMore: PropTypes.bool,
    request: PropTypes.shape({
        unique_key: PropTypes.string,
        requesting_org_name: PropTypes.string,
        facility_or_business_type: PropTypes.string,
        vaccination_wanted_indiv_num: PropTypes.number,
        org_address: PropTypes.string,
        org_address_additional: PropTypes.string,
        org_zipcode: PropTypes.string,
        org_description: PropTypes.string,
        identified_space: PropTypes.bool,
        contact_email: PropTypes.string,
        contact_name: PropTypes.string,
        contact_phone: PropTypes.string,
        submitdate: PropTypes.string,
        accept_date: PropTypes.string,
        first_vaccine_date: PropTypes.string,
        second_vaccine_date: PropTypes.string,
        vaccination_event_type: PropTypes.string,
        part_of_larger_event: PropTypes.bool,
        vaccination_status_assessed: PropTypes.bool,
        edu_event_topics_to_cover: PropTypes.string,
        covid_vaccine_preference: PropTypes.string,
        target_demographics: PropTypes.string,
        insurance_status: PropTypes.string,
        event_location: PropTypes.string,
        request_time_of_event: PropTypes.string,
        hosting_plan_indoor_outdoor: PropTypes.string,
        on_site_amenities: PropTypes.string,
        facility_capacity_bad_weather: PropTypes.bool,
        language_needs: PropTypes.string,
        staff_volunteers_avail: PropTypes.bool,
        general_comments: PropTypes.string
    }),
    type: PropTypes.string
}

export default Card;