import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { AppContext } from '../../context/main';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const CompleteModal = (props) => {
    const [ open, setOpen ] = useState(false);
    const { providerComplete } = useContext(AppContext);

    const {
        values,
        errors,
        touched,
        getFieldProps,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange
    } = useFormik({
        initialValues: {
            'unique_key': props.requestID,
            'provider_confirmed': '',
            'actual_number_vaccinated': ''
        },
        /* eslint-disable camelcase */
        validationSchema: Yup.object()
            .shape({
                provider_confirmed: Yup.bool()
                    .required('Required')
            })
            .when((vals, schema) => {
                if (vals.provider_confirmed === true && props.eventType != 'Educational event') {
                  return schema.shape({
                    actual_number_vaccinated: Yup.number()
                        .typeError('Vaccines given must be a number')
                        .positive('Number must be greater than 0')
                        .integer('Number must be a whole number')
                        .required('Vaccine number required')
                  });
                }
            }),
        // eslint-disable-next-line
        onSubmit(values) {
            if (values[ 'provider_confirmed' ] != true) {
                values[ 'actual_number_vaccinated' ] = null;
            }
            providerComplete(values);
            setOpen(false);
        }
    });

    const theme = createTheme({
        palette: {
            secondary: {
                main: '#c6f6d5'
            }
        }
    });

    const formikProps = (name, initialValue = '') => ({
        id: name,
        name: name,
        value: values[ name ],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched[ name ] && Boolean(errors[ name ]),
        helperText: touched[ name ] ? errors[ name ] : ''
    });    

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        values.provider_confirmed = '';
        values.actual_number_vaccinated = '';
        setOpen(false);
    };

    return (
        <>
            <button className="bg-secondary-light text-black shadow-md w-36 py-2 rounded-lg text-center cursor-pointer mx-auto md:mx-3.5 mt-2 hover:bg-secondary focus:outline-none"
                type="button" onClick={ handleOpen }>
                Mark Complete
            </button>
            <Modal
                aria-labelledby="complete-modal-title"
                aria-describedby="complete-modal-description"
                className="justify-center items-center flex"
                open={ open }
                onClose={ handleClose }
                closeAfterTransition
                BackdropComponent={ Backdrop }
                BackdropProps={ {
                    timeout: 500,
                } }
            >
                <Fade in={ open }>
                    <div className="bg-white pt-2 px-4 pb-3 w-1/3">
                        <h2 id="complete-modal-title">Complete</h2>
                        <p id="complete-modal-description">If complete, include how many vaccines were actually given. If the event did not take place, please mark as not complete.</p>
                        <form onSubmit={ handleSubmit }>
                            <div className="mb-2 w-full">
                                <Select
                                    id='provider_confirmed'
                                    name='provider_confirmed'
                                    { ...getFieldProps('provider_confirmed') }
                                    onChange={ (e) => {
                                        const { value } = e.target;
                                        console.log(props);
                                        handleChange;
                                        let convertedVal = value;
                                        if (value != '') {
                                            convertedVal = (value === 'true');
                                        }
                                        console.log(convertedVal)
                                        setFieldValue('provider_confirmed', convertedVal);
                                    } }
                                    onBlur={ handleBlur }
                                    fullWidth
                                    variant='outlined'
                                    displayEmpty
                                >
                                    <MenuItem value=''>--Select Event Closure Status--</MenuItem>
                                    <MenuItem value='true'>Yes - Event occured</MenuItem>
                                    <MenuItem value='false'>No - Event did NOT occur</MenuItem>
                                </Select>
                            </div>                            
                            <div className="mb-2 w-full">
                                {
                                    // only show if confirmed -- and later add in if not educational event // && props.eventType == 'Educational event'
                                    (values.provider_confirmed == true && props.eventType != 'Educational event') ?
                                    
                                        <TextField
                                            { ...formikProps('actual_number_vaccinated') }
                                            label='Actual number vaccinated*'
                                            fullWidth
                                            variant='outlined'
                                            disabled={ values.provider_confirmed != true }
                                        />
                                    : 
                                        <></>
                                }

                            </div>
                            <div className='justify-between'>
                                <ThemeProvider theme={ theme }>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        color='secondary'
                                        className='mx-auto'
                                        disabled={ values.provider_confirmed === '' }
                                    >Submit</Button>
                                    <Button
                                        className='float-right'
                                        variant='contained'
                                        color='default'
                                        onClick={ handleClose }
                                    >Cancel</Button>
                                </ThemeProvider>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

/* eslint-disable camelcase */
CompleteModal.propTypes = {
    requestID: PropTypes.string,
    eventType: PropTypes.string,
    values: PropTypes.shape({
        unique_key: PropTypes.string,
        provider_confirmed: PropTypes.bool,
        actual_number_vaccinated: PropTypes.number
    })
}

export default CompleteModal;