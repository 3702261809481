import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const AppContext = createContext();
const AppContextProvider = ({ children }) => {
    const [ state, setState ] = useState({
        validKey: false,
        cardProcessing: false,
        providerInfo: {},
        data: {
            available: [],
            accepted: []
        },
        loading: true,
        loadingMessage: 'Loading...',
        error: '',
        requestMessage: '',
        showToast: false,
        toast: { }
    });

    const toastMethod = (open, message, severity) => {
        if (open) {
            setState(prevState => ({
                ...prevState,
                showToast: true,
                toast: {
                    message: message,
                    severity: severity
                }
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                showToast: false,
                toast: { }
            }));
        }
    };

    const getRequests = async () => {
        const retrieveTime = new Date().toLocaleString();
        // set to loading with appropriate message
        setState(prevState => ({
            ...prevState,
            loadingMessage: 'Retrieving  request...',
            loading: true
        }));
        // check for URL params and get 'key' parameter
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const key = params.get('key');

        const rejectHandler = reason => ({ status: 'rejected', reason });
        const resolveHandler = value => ({ status: 'fulfilled', value });

        const getFetch = function (url) {
            return fetch(url)
            .then(res => res.json())
            .then( (result) => {
                return result;
            })
            .catch((error) => {
                console.log(error);
            });
        }

        // create all settled function to handle silent rejections
        Promise.allSettled = function (promises) {
            const convertedPromises = promises.map(p => Promise.resolve(p).then(resolveHandler, rejectHandler));
            return Promise.all(convertedPromises);
        };

        Promise.allSettled([ getFetch(process.env.REACT_APP_CONNECT_URL + '/getAvailable?key=' + key), getFetch(process.env.REACT_APP_CONNECT_URL + '/getAccepted?key=' + key) ]).then((result) => {
            let available = [];
            const accepted = [];
            let scheduledTemp = [];
            const scheduled = [];
            let completed = [];
            // get available
            if (result[ 0 ].status == 'fulfilled') {
                // sort newest to oldest
                available = result[ 0 ].value.data.sort((a, b) => a.submitdate < b.submitdate ? 1 : -1);
            }
            // get those accepted by this provider
            if (result[ 1 ].status == 'fulfilled') {
                // map through and assign to columns
                result[ 1 ].value.data
                    // start by sorting all by accepted date
                    .sort((a, b) => a.accept_date > b.accept_date ? 1 : -1)
                    .map((request) => {
                        if (request.first_vaccine_date != null) {
                            if (request.provider_confirmed != null){
                                completed.push(request);
                            } else {
                                scheduledTemp.push(request);
                            }
                        } else {
                            accepted.push(request);
                        }
                    })
            }
            // sort scheduled oldest to newest
            scheduledTemp = scheduledTemp.sort((a, b) => a.first_vaccine_date > b.first_vaccine_date ? 1 : -1);
            completed = completed.sort((a, b) => a.first_vaccine_date < b.first_vaccine_date ? 1 : -1);
            // slice out complete to top
            scheduledTemp.map(req => {
                let lastVaccineDate = new Date(req.first_vaccine_date);
                const todayDate = new Date();
                todayDate.setDate(todayDate.getDate() - 1);               
                if (req.second_vaccine_date) {
                    lastVaccineDate = new Date(req.second_vaccine_date);
                }
                if (lastVaccineDate <= todayDate) {
                    scheduled.unshift(req);
                } else {
                    scheduled.push(req);
                }
            })
            setState(prevState => ({
                ...prevState,
                loading: false,
                data: {
                    available: available,
                    accepted: accepted,
                    scheduled: scheduled,
                    completed: completed,
                    refreshedTime: retrieveTime
                }
            }));
        });
    }

    const checkAPI = () => {
        // check for URL params and get 'key' parameter
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const key = params.get('key');
        if (key) {
            // query connect api and see if valid
            fetch(process.env.REACT_APP_CONNECT_URL + '/checkProvider?key=' + key)
            .then(res => res.json())
            .then(
                (result) => {
                    // if success - show results
                    if (result.success) {
                        const resultData = result.data[ 0 ];
                        /* eslint-disable camelcase */
                        resultData.provider_key = key;
                        setState(prevState => ({
                            ...prevState,
                            validKey: true,
                            providerInfo: resultData
                        }));
                    } else {
                        // if not checked correct - set loaded but not checked
                        setState(prevState => ({
                            ...prevState,
                            validKey: false,
                            loading: false
                        }));
                    }
                }
            )
        } else {
            setState(prevState => ({
                ...prevState,
                validKey: false,
                loading: false
            }));
        }
    }

    const acceptRequest = (id) => {
        // set to loading with appropriate message
        setState(prevState => ({
            ...prevState,
            loadingMessage: 'Processing request...',
            loading: true
        }));
        // create object to submit
        var acceptRecord = {
            'unique_key': id,
            'provider_accepted_request': state.providerInfo.provider_id
        };
        // submit to connect API
        fetch(process.env.REACT_APP_CONNECT_URL, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(acceptRecord)
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                toastMethod(true, 'This request has been accepted!', 'success');
            } else {
                // if error - show message
                toastMethod(true, data.message, 'error');
            }
        })
        .catch(error => {
            // if error - catch and send to user
            toastMethod(true, error, 'error');
        });
    };

    const releaseRequest = (id) => {
        // set to loading with appropriate message
        setState(prevState => ({
            ...prevState,
            loadingMessage: 'Processing request...',
            loading: true
        }));

        // submit to review API as PUT
        fetch(process.env.REACT_APP_REVIEW_URL + '/releaseAccepted?unique_key=' + id, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                toastMethod(true, 'This request has been released!', 'success');
            } else {
                // if error - show message
                toastMethod(true, data.message, 'error');
            }
        })
        .catch(error => {
            // if error - catch and send to user
            toastMethod(true, error, 'error');
        });
    };

    const scheduleRequest = (scheduleObj) => {
        // set to loading with appropriate message
        setState(prevState => ({
            ...prevState,
            loadingMessage: 'Processing request...',
            loading: true
        }));

        // submit to connect API
        fetch(process.env.REACT_APP_CONNECT_URL + '/schedule', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(scheduleObj)
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                toastMethod(true, 'This request has been scheduled!', 'success');
            } else {
                // if error - show message
                toastMethod(true, data.message, 'error');
            }
        })
        .catch(error => {
            // if error - catch and send to user
            toastMethod(true, error, 'error');
        });
    };

    const providerComplete = (completeObj) => {
        // set to loading with appropriate message
        setState(prevState => ({
            ...prevState,
            loadingMessage: 'Processing request...',
            loading: true
        }));
        // create object to submit
        var confirmRecord = {
            'unique_key': completeObj.unique_key,
            'provider_confirmed': completeObj.provider_confirmed,
            'actual_number_vaccinated': parseInt(completeObj.actual_number_vaccinated)
        };
        // submit to connect API
        fetch(process.env.REACT_APP_CONNECT_URL + '/confirm', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(confirmRecord)
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                toastMethod(true, 'This request has been confirmed!', 'success');
            } else {
                // if error - show message
                toastMethod(true, data.message, 'error');
            }
        })
        .catch(error => {
            // if error - catch and send to user
            toastMethod(true, error, 'error');
        });
    };

    const requestorComplete = (id, val) => {
        // set to loading with appropriate message
        setState(prevState => ({
            ...prevState,
            loadingMessage: 'Processing request...',
            loading: true
        }));
        if (id != null && val != null) {
            if (val == 'true' || val == 'false') {
                // create object to submit
                var confirmRecord = {
                    'unique_key': id,
                    'requester_confirmed': (val == 'true')
                };
                // submit to connect API
                fetch(process.env.REACT_APP_CONNECT_URL + '/confirm', {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(confirmRecord)
                })
                .then(res => res.json())
                .then(data => {
                    let returnMessage = '';
                    if (data.success) {
                        returnMessage = 'Confirmation was successful. Thank you for submitting!'
                    } else {
                        returnMessage = data.message;
                    }
                    setState(prevState => ({
                        ...prevState,
                        loading: false,
                        requestMessage: returnMessage
                    }));
                })
                .catch(error => {
                    // if error - catch and send to user
                    setState(prevState => ({
                        ...prevState,
                        loading: false,                    
                        error: error
                    }));
                });
            } else {
                // if error - catch and send to user
                setState(prevState => ({
                    ...prevState,
                    loading: false,                    
                    requestMessage: 'Error with values in URL.  Please check and try again'
                }));
            }
        } else {
            // if error - catch and send to user
            setState(prevState => ({
                ...prevState,
                loading: false,                    
                requestMessage: 'Missing values in URL.  Please check and try again'
            }));
        }
    };

    const emailRequest = (requestObj) => {
        // set to loading with appropriate message
        setState(prevState => ({
            ...prevState,
            loadingMessage: 'Processing request...',
            loading: true
        }));

        // submit to connect API
        fetch(process.env.REACT_APP_CONNECT_URL + '/request', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestObj)
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                toastMethod(true, 'This email was sent successfully!', 'success');
            } else {
                // if error - show message
                toastMethod(true, data.message, 'error');
            }
        })
        .catch(error => {
            // if error - catch and send to user
            toastMethod(true, error, 'error');
        });
    };

    return (
        <AppContext.Provider value={ { state, getRequests, checkAPI, acceptRequest, toastMethod, releaseRequest, scheduleRequest, providerComplete, requestorComplete, emailRequest } }>
            { children }
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.any
}

export { AppContext, AppContextProvider };